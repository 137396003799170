.container {
  background: black;
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  /* justify-content: center; */
  padding-top: 20px;
  overflow-y: scroll;
}

.login-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 200px;
  height: 400px;
  width: 500px;
}

.logo-div {
  position: absolute;
  top: 100px;
  /* background: red; */
}

.record-data {
  background: #f5f5f5;
  box-shadow: 1px 2px 12px 1px gainsboro;
  overflow: hidden;
  -webkit-backdrop-filter: blur(1rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 60px;
  height: 35px;
  border-radius: 7px;
  margin: 10px;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}

.horizontal-scroll {
  margin-bottom: 50px;
  /* background: red; */
  display: flex;
  overflow-x: scroll;
  width: 100vw;
}
