.input-container {
  padding: 0px 10px 10px 10px;
  position: relative;
  background: white;
  border-radius: 3px;
}

.input {
  padding: 16px 0px 10px;
  font-size: 16px;
  /* border: 2px solid #ccc; */
  border-color: #ccc;
  border-width: 0px 0px 2px 0px;
  /* border-radius: 10px; */
  outline: none;
  width: 300px;
  transition: border-color .3s ease;
  background: transparent;
}

.input:hover {
  border-color: black;
}
.input:focus {
  border-color: #3f51b5;
}

.label {
  /* left: 10px; */
  background-color: transparent;
  /* padding: 0 4px; */
  transition: all 0.2s ease;
  pointer-events: none;
}
