.button {
    min-width: 150px;
    max-width: 200px;
    height: 40px;
    background: none;
    border-radius: 30px;
    border: 3px solid ghostwhite;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }